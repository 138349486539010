<template>
	<div class="checksWrapper mh-5 mt-5">
		<Checks :editable="true" />
	</div>
</template>

<script>
	import Checks from "./Checks"

	export default {
		name: "ChecksAdmin",
		components: { Checks }
	}
</script>

<style lang="scss">
	.checksWrapper {
	}
</style>
